<template>
  <div class="mescroll-view">
    <div class="th">
      <div class="td">Time</div>
      <div class="td">Price($)</div>
      <div class="td">Amount(Fxs)</div>
      <div class="td">Amount($)</div>
      <div class="td">User</div>
    </div>
    <div class="tr" v-for="(item, index) in dataList" :key="index">
      <div class="td">{{ item.created_time | handleDate }}</div>
      <div
        class="td"
        :style="{ color: item.type === 1 ? '#26A69A' : '#EF5350' }"
      >
        {{ item.price }}
      </div>
      <div
        class="td"
        :style="{ color: item.type === 1 ? '#26A69A' : '#EF5350' }"
      >
        {{ item.amount }}
      </div>
      <div
        class="td"
        :style="{ color: item.type === 1 ? '#26A69A' : '#EF5350' }"
      >
        {{ item.toamount }}
      </div>
      <div class="td">*{{ item.address.replace(/^0x/, "").slice(-4) }}</div>
    </div>
  </div>
</template>

<script>
import { gettradelist, getnewtrade } from "@/request/api";
import dayjs from "dayjs";
export default {
  filters: {
    handleDate(v) {
      return dayjs(v).format("HH:mm");
    },
  },
  props: {
    test: {
      type: String,
      default() {
        return "";
      },
      required: false,
    },
  },
  data() {
    return {
      dataList: [], // 列表数据
      id: "",
      setI: null,
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    if (this.setI) {
      clearInterval(this.setI);
      this.setI = null;
    }
  },
  methods: {
    async getData() {
      try {
        const res = await gettradelist();
        if (res.code !== 200) {
          this.$toast.fail(res.msg);
          return;
        }
        this.dataList = res.data;
        this.id = res.data.length > 0 ? res.data[res.data.length - 1].id : "";
        this.setPolling();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error,
        });
      }
    },
    setPolling() {
      const setI = setInterval(() => {
        this.listPolling();
      }, 3000);
      this.setI = setI;
    },
    async listPolling() {
      try {
        const res = await getnewtrade({
          id: this.id,
        });
        this.id = res.data.length > 0 ? res.data[res.data.length - 1].id : "";
        this.dataList.concat(res.data);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mescroll-view {
  .th,
  .tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    .td {
      flex: 1;
      overflow: hidden;
      text-align: center;
      font-size: 26px;
      &:first-child {
        text-align: left;
        flex: 0.5;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
