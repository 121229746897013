<template>
  <div>
    <van-tabs v-model="active" @click="handleTabClick">
      <van-tab
        v-for="(item, index) in tabs"
        :key="index"
        :title="item.label"
        :name="item.value"
      ></van-tab>
    </van-tabs>
    <div class="klinechart" id="klinechart"></div>
  </div>
</template>

<script>
import klinecharts from "klinecharts";
import { getKlinelist, getnewKline } from "@/request/api";
export default {
  props: {
    test: {
      type: String,
      default() {
        return "";
      },
      required: false,
    },
  },
  data() {
    return {
      tabs: [
        {
          label: "1" + this.$t("common.minute"),
          value: "kline_1m",
        },
        {
          label: "5" + this.$t("common.minute"),
          value: "kline_5m",
        },
        {
          label: "15" + this.$t("common.minute"),
          value: "kline_15m",
        },
        {
          label: "30" + this.$t("common.minute"),
          value: "kline_30m",
        },
        {
          label: "1" + this.$t("common.hour"),
          value: "kline_1h",
        },
        {
          label: "4" + this.$t("common.hour"),
          value: "kline_4h",
        },
        {
          label: "1" + this.$t("common.day"),
          value: "kline_1d",
        },
        {
          label: "5" + this.$t("common.day"),
          value: "kline_5d",
        },
        {
          label: "7" + this.$t("common.day"),
          value: "kline_7d",
        },
        {
          label: "1" + this.$t("common.month"),
          value: "kline_1mon",
        },
      ],
      active: "kline_1m",
      chart: null,
      finalDate: "",
      setI: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.setI) {
      clearInterval(this.setI);
      this.setI = null;
    }
  },
  methods: {
    async init() {
      if (!this.chart) {
        this.chart = klinecharts.init(`klinechart`, {
          candle: {
            tooltip: {
              labels: this.$t("klinechart.labels"),
              showRule: 'follow_cross',
              showType: 'rect',
            },
          },
        });
      }
      this.chart.setPriceVolumePrecision(6, 6);
      try {
        const klinelist = await getKlinelist({ type: this.active });
        if (klinelist.code !== 200) {
          this.$toast.fail(klinelist.msg);
          return;
        }
        const arr = klinelist.data.map((item) => {
          return {
            close: Number(item.close),
            high: Number(item.high),
            low: Number(item.low),
            open: Number(item.open),
            timestamp: Number(item.date) * 1000,
            volume: Number(item.volume),
          };
        });
        this.chart.applyNewData(arr);
        this.finalDate =
          arr.length > 0 ? arr[arr.length - 1].timestamp / 1000 : "";
        if (!this.setI) {
          this.setPolling();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error,
        });
      }
    },
    setPolling() {
      const setI = setInterval(() => {
        this.klinePolling();
      }, 3000);
      this.setI = setI;
    },
    async klinePolling() {
      try {
        const res = await getnewKline({
          type: this.active,
          date: this.finalDate,
        });
        res.data.forEach((item, index) => {
          this.chart.updateData({
            close: Number(item.close),
            high: Number(item.high),
            low: Number(item.low),
            open: Number(item.open),
            timestamp: Number(item.date) * 1000,
            volume: Number(item.volume),
          });
          this.finalDate = res.data[index].timestamp;
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error,
        });
      }
    },
    handleTabClick() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.klinechart {
  width: 100%;
  height: 500px;
}
</style>
