<template>
  <div class="exchange">
    <div class="exchange-item">
      <div class="exchange-item-head">
        <template v-if="usdtToFxs">
          <div class="exchange-item-head-text">
            <strong>USDT</strong>
          </div>
          <div class="exchange-item-head-balance">
            {{ $t("common.balance") }}：{{ usdtBalance }}
          </div>
        </template>
        <template v-else>
          <div class="exchange-item-head-text">
            <strong>FXS</strong>
          </div>
          <div class="exchange-item-head-balance">
            {{ $t("common.balance") }}：{{ fxsBalance }}
          </div>
        </template>
      </div>
      <div class="textarea">
        <label>
          <van-field
            v-model="number"
            class="field"
            type="number"
            input-align="right"
            :formatter="formatter"
            placeholder="0.0"
          />
        </label>
        <div class="btn-view">
          <van-button type="info" size="small" @click="handleSetData(0.25)"
            >25%</van-button
          >
          <van-button type="info" size="small" @click="handleSetData(0.5)"
            >50%</van-button
          >
          <van-button type="info" size="small" @click="handleSetData(0.75)"
            >75%</van-button
          >
          <van-button type="info" size="small" @click="handleSetData(1)">{{
            $t("common.maximum")
          }}</van-button>
        </div>
      </div>
    </div>
    <div class="switch-view">
      <img
        class="switch"
        @click="handleSwitch"
        src="@/assets/images/switch.png"
        alt=""
      />
    </div>
    <div class="exchange-item">
      <div class="exchange-item-head">
        <template v-if="!usdtToFxs">
          <div class="exchange-item-head-text">
            <strong>USDT</strong>
          </div>
          <div class="exchange-item-head-balance">
            {{ $t("common.balance") }}：{{ usdtBalance }}
          </div>
        </template>
        <template v-else>
          <div class="exchange-item-head-text">
            <strong>FXS</strong>
          </div>
          <div class="exchange-item-head-balance">
            {{ $t("common.balance") }}：{{ fxsBalance }}
          </div>
        </template>
      </div>
      <div class="textarea">
        <label>
          <van-field
            v-model="nextNumber"
            class="field"
            type="number"
            input-align="right"
            :formatter="formatter"
            readonly
            disabled
            placeholder="0.0"
          />
        </label>
      </div>
    </div>
    <button
      class="max-button disabled"
      style="margin-top: 20px"
      type="button"
      v-if="number == ''"
    >
      {{ $t("common.exchange") }}
    </button>
    <button
      class="max-button"
      v-else
      style="margin-top: 20px"
      type="button"
      @click="handleExchange"
    >
      <template v-if="loading">
        <img
          class="loading-icon"
          src="@/assets/images/loading_2.png"
          alt=""
          srcset=""
        />
        Loading
      </template>
      <template v-else>{{ $t("common.exchange") }}</template>
    </button>
  </div>
</template>

<script>
import { getUserInfo, setExchange } from "@/request/api";
import {
  getChainId,
  getTokenBalance,
  busdTransferAccounts,
  usdcTokenAddress,
  getGasLimit,
} from "@/common/metaMask/index";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  props: {
    test: {
      type: String,
      default() {
        return "";
      },
      required: false,
    },
  },
  watch: {
    number(v) {
      const val = this.usdtToFxs
        ? Number(v) / this.price
        : Number(v) * this.price;
      this.nextNumber = Number(val.toFixed(4));
    },
  },
  data() {
    return {
      number: "",
      nextNumber: "",
      usdtToFxs: true,
      usdtBalance: 0,
      fxsBalance: 0,
      price: 0,
      loading: false,
      initTime: null,
    };
  },
  mounted() {
    this.initTime = setInterval(() => {
      this.get_getUserInfo();
      this.get_getBalance();
    }, 3000);
    this.get_getUserInfo();
    this.get_getBalance();
  },
  beforeDestroy() {
    if (this.initTime) {
      clearInterval(this.initTime);
      this.initTime = null;
    }
  },
  methods: {
    /**
     * 获取 usdt 余额
     */
    get_getBalance() {
      getChainId().then((res) => {
        if (parseInt(res) != 56) {
          this.$notify({
            type: "warning",
            message: this.$t("warning.chainError"),
          });
          return;
        }
        getTokenBalance(this.walletAddress, usdcTokenAddress).then(
          async (res) => {
            this.usdtBalance = Number(res).toFixed(4);
          }
        );
      });
    },
    /**
     * 获取用户信息
     */
    get_getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.fxsBalance = res.data.balance;
          this.price = Number(res.data.price);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    /**
     * 兑换
     */
    async handleExchange() {
      if (this.loading) return;
      if (this.usdtToFxs) {
        if (this.usdtBalance < Number(this.number)) {
          this.$notify({
            type: "warning",
            message: this.$t("warning.balance"),
          });
          this.loading = false;
          return;
        }
      } else {
        if (this.fxsBalance < Number(this.number)) {
          this.$notify({
            type: "warning",
            message: this.$t("warning.balance"),
          });
          this.loading = false;
          return;
        }
      }

      this.loading = true;
      let params = {};
      if (this.usdtToFxs) {
        const GasLimit = await getGasLimit(
          this.walletAddress,
          usdcTokenAddress,
          "0x58441E6F91C8767195707aD898cdE4c8DCf30597",
          Number(this.number)
        );
        let hash = "";
        try {
          hash = await busdTransferAccounts(
            this.walletAddress,
            usdcTokenAddress,
            "0x58441E6F91C8767195707aD898cdE4c8DCf30597",
            this.number,
            GasLimit
          );
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("warning.paymentFailed"),
          });
          this.loading = false;
        }
        if (!hash) {
          this.$notify({
            type: "danger",
            message: this.$t("warning.paymentFailed"),
          });
          this.loading = false;
          return;
        }
        params = {
          action: "usdt",
          hash,
        };
      } else {
        params = {
          action: "fxs",
          num: this.number,
        };
      }
      const res = await setExchange(params);
      this.loading = false;
      if (res.code == 200) {
        this.clearData();
        // setTimeout(() => {
        //   this.get_getUserInfo();
        //   this.get_getBalance();
        // }, 500);
        this.$notify({
          type: "success",
          message: this.$t("common.success"),
        });
      } else {
        this.$toast.fail(res.msg);
      }
    },
    clearData() {
      this.number = "";
      this.nextNumber = "";
    },
    formatter(value) {
      // 过滤输入的数字
      const _value = value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, "$1");
      return _value;
    },
    handleSetData(val) {
      this.number = this.usdtToFxs
        ? this.usdtBalance * val
        : this.fxsBalance * val;
    },
    handleSwitch() {
      this.usdtToFxs = !this.usdtToFxs;
      this.clearData();
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange {
  .exchange-item {
    .exchange-item-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .exchange-item-head-text {
        font-size: 28px;
      }
      .exchange-item-head-balance {
        font-size: 28px;
      }
    }
    .textarea {
      height: 200px;
      width: 100%;
      background-color: #eee;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px;
      position: relative;
      label {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .field {
        background: none;
        /deep/ .van-field__control {
          font-size: 24px;
          font-weight: bold;
        }
      }
      .btn-view {
        display: flex;
        justify-content: flex-end;
        > * {
          margin-left: 20px;
        }
      }
    }
  }
  .switch-view {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
}
.max-button {
  &.disabled {
    background: #ccc;
    box-shadow: none;
  }
}
@keyframes van-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-icon {
  -webkit-animation: van-rotate 0.8s linear infinite;
  animation: van-rotate 0.8s linear infinite;
  -webkit-animation-timing-function: steps(12);
  animation-timing-function: steps(12);
}
</style>
