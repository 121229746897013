<template>
  <div class="container">
    <div class="exchange-content">
      <exchange />
    </div>
    <div class="klinechart-content">
      <klinechart />
    </div>
    <div class="data-list-content">
      <data-list />
    </div>
  </div>
</template>

<script>
import exchange from "./module/exchange.vue";
import klinechart from "./module/klinechart.vue";
import dataList from "./module/data-list.vue";
export default {
  components: {
    exchange,
    klinechart,
    dataList,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff !important;
  .exchange-content {
    margin-bottom: 30px;
  }
  .klinechart-content {
    
    margin-bottom: 30px;
  }
  //   .data-list-content {
  //     height: 500px;
  //   }
}
</style>
